import React, { Component } from 'react';
import { ConnectedRouter } from 'react-router-redux';
import { Route, Switch } from 'react-router-dom';
import { history } from './store';
import PropTypes from 'prop-types';
import { connect, ReactReduxContext } from 'react-redux';
import {
  Admin,
  InactiveUserOrAdmin,
  UserConsumer,
  UserLoadingOperator,
  UserLoadingOperatorOrAdmin,
  UserOrAdmin,
  UserProducerOrAdmin,
  UserProviderOrAdmin,
  UserSeller,
  UserSellerOrAdmin,
  UserSellerProviderLoadingOperatorOrAdmin,
  UserTraderOrAdmin,
} from './utils/roles';
import { ConfigProvider, Layout } from 'antd';
import { changeLocaleActionCreator } from './actions/actionCreators/settingsActionCreators';
import antdEn from 'antd/lib/locale-provider/en_US';
import antdEt from 'antd/lib/locale-provider/et_EE';
import { getLocale } from './reducers/settingsReducer';
import { getUserPersonalCode } from './reducers/userReducer';
import { AddEquipment, Alerts, Sidebar } from './containers';
import i18n from './i18n';
import { fuelType } from './constants/classificators';

import {
  AccountGOSOverview,
  AccountOverview,
  CancelingCertificates,
  Classifiers,
  ClassifierSubValuesView,
  ClassifierSubView,
  ClassifierView,
  ConsumerReports,
  ConsumptionPointAdd,
  Cookies,
  ElectricMeteringPoint,
  ElectricMeteringPointList,
  Error403,
  InvoiceSearch,
  InvoiceView,
  Landing,
  LegalEntityEdit,
  LoadingUnitAdd,
  LoadingUnitList,
  LoadingUnitSearch,
  LoadingUnitView,
  MarketParticipants,
  MeteringPoint,
  MeteringPointList,
  Notifications,
  PortletError,
  PreTradingAgreementReservations,
  PreTradingAgreementView,
  ProductionUnitAdd,
  ProductionUnitSearch,
  ProductionUnitView,
  Register,
  ReportPage,
  TradingAccountLockView,
  TradingAccountTransferView,
  TradingPlatform,
  TransactionGOSView,
  TransactionSearch,
  TransactionView,
  TransferringCertificates,
  TsAgreementRegistrationView,
  TsAuctionDetailsView,
  TsAuctionHistory,
  VDSMessages,
  VDSTools,
} from './pages';
import AddTsAuctionView from './pages/TsAuctionView/AddTsAuctionView';

const { Content } = Layout;

const antdLocales = {
  en: antdEn,
  et: antdEt,
};

class Root extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    personalCode: PropTypes.string,
    changeLocaleActionCreator: PropTypes.func.isRequired,
  };

  static defaultProps = {
    personalCode: '',
  };

  componentDidMount = () => {
    this.props.changeLocaleActionCreator(i18n.language);
  };

  render() {
    const { locale } = this.props;
    const antdLocale = antdLocales[locale];
    const componentToFunction = component => props =>
      React.createElement(component, props);
    const componentToFunctionWithExtraProps = (
      component,
      extraProps = {}
    ) => props => React.createElement(component, { ...props, ...extraProps });

    return (
      <ConfigProvider locale={antdLocale}>
        <div>
          <Alerts />
          <ReactReduxContext.Consumer>
            {({ store }) => (
              <ConnectedRouter history={history} store={store}>
                <Layout>
                  <Switch>
                    <Route exact path="/login" component={null} />
                    <Route
                      exact
                      path="/postlogin/:loginCode"
                      component={null}
                    />
                    <Route exact path="/register" component={null} />
                    <Route exact path="/register/*" component={null} />
                    <Route exact path="/consumer/*" component={null} />
                    <Route exact path="/error/*" component={null} />
                    <Route exact path="/admin/*" component={null} />
                    <Route
                      component={componentToFunction(UserOrAdmin(Sidebar))}
                    />
                  </Switch>
                  <Content>
                    <Switch>
                      <Route
                        exact
                        path="/login"
                        component={componentToFunction(Landing)}
                      />
                      <Route
                        exact
                        path="/search"
                        component={componentToFunction(
                          Admin(MarketParticipants)
                        )}
                      />
                      <Route
                        exact
                        path="/loadingUnit/list"
                        component={componentToFunction(
                          UserLoadingOperator(LoadingUnitList)
                        )}
                      />
                      <Route
                        exact
                        path="/loadingUnit/search"
                        component={componentToFunction(
                          Admin(LoadingUnitSearch)
                        )}
                      />
                      <Route
                        exact
                        path="/loadingUnit/add"
                        component={componentToFunction(
                          UserLoadingOperatorOrAdmin(LoadingUnitAdd)
                        )}
                      />
                      <Route
                        exact
                        path="/loadingUnit/:loadingUnitId"
                        component={componentToFunction(
                          UserLoadingOperatorOrAdmin(LoadingUnitView)
                        )}
                      />
                      <Route
                        exact
                        path="/postlogin/:loginCode"
                        component={componentToFunction(Landing)}
                      />
                      <Route
                        exact
                        path="/VDSMessages"
                        component={componentToFunction(Admin(VDSMessages))}
                      />
                      <Route
                        exact
                        path="/admin/vdsTools"
                        component={componentToFunction(Admin(VDSTools))}
                      />
                      <Route
                        exact
                        path="/admin/vdsTools/*"
                        component={componentToFunction(Admin(VDSTools))}
                      />
                      <Route
                        exact
                        path="/meteringPointList"
                        component={componentToFunction(
                          UserOrAdmin(MeteringPointList)
                        )}
                      />
                      <Route
                        exact
                        path="/electricMeteringPointList/:tabKey?"
                        component={componentToFunction(
                          UserOrAdmin(ElectricMeteringPointList)
                        )}
                      />
                      <Route
                        exact
                        path="/notifications"
                        component={componentToFunction(
                          UserOrAdmin(Notifications)
                        )}
                      />
                      <Route
                        exact
                        path="/cookies"
                        component={componentToFunction(UserOrAdmin(Cookies))}
                      />
                      <Route
                        exact
                        path="/register/:registerCode?"
                        component={componentToFunction(
                          InactiveUserOrAdmin(Register)
                        )}
                      />
                      <Route
                        exact
                        path="/legalEntity/:registerCode"
                        component={componentToFunction(
                          UserOrAdmin(LegalEntityEdit)
                        )}
                      />
                      <Route
                        exact
                        path="/account/:accountId"
                        component={componentToFunction(
                          UserOrAdmin(AccountOverview)
                        )}
                      />
                      <Route
                        exact
                        path="/accountReport/:accountId?"
                        component={componentToFunction(
                          UserOrAdmin(AccountOverview)
                        )}
                      />
                      <Route
                        exact
                        path="/productionUnit/search"
                        component={componentToFunctionWithExtraProps(
                          Admin(ProductionUnitSearch),
                          { fuelType: fuelType.BIOMETHANE }
                        )}
                      />
                      <Route
                        exact
                        path="/hydrogenProductionUnit/search"
                        component={componentToFunctionWithExtraProps(
                          Admin(ProductionUnitSearch),
                          { fuelType: fuelType.HYDROGEN }
                        )}
                      />
                      <Route
                        exact
                        path="/meteringPoint/:meteringPointId/:tabKey?"
                        component={componentToFunction(
                          UserOrAdmin(MeteringPoint)
                        )}
                      />
                      <Route
                        exact
                        path="/electricMeteringPoint/:meteringPointId/:tabKey?"
                        component={componentToFunction(
                          UserOrAdmin(ElectricMeteringPoint)
                        )}
                      />
                      <Route
                        exact
                        path="/consumptionPoint"
                        component={componentToFunction(
                          UserSellerOrAdmin(ConsumptionPointAdd)
                        )}
                      />
                      <Route
                        exact
                        path="/productionUnit/add"
                        component={componentToFunctionWithExtraProps(
                          Admin(ProductionUnitAdd),
                          { fuelType: fuelType.BIOMETHANE }
                        )}
                      />
                      <Route
                        exact
                        path="/hydrogenProductionUnit/add"
                        component={componentToFunctionWithExtraProps(
                          Admin(ProductionUnitAdd),
                          { fuelType: fuelType.HYDROGEN }
                        )}
                      />
                      <Route
                        exact
                        path="/productionUnit/:productionUnitId"
                        component={componentToFunction(
                          Admin(ProductionUnitView)
                        )}
                      />
                      <Route
                        exact
                        path="/transaction/search/go"
                        component={componentToFunction(
                          UserOrAdmin(TransactionSearch)
                        )}
                      />
                      <Route
                        exact
                        path="/transaction/search/gos"
                        component={componentToFunction(
                          Admin(TransactionSearch)
                        )}
                      />
                      <Route
                        exact
                        path="/transaction/search/:accountId?"
                        component={componentToFunction(
                          UserOrAdmin(TransactionSearch)
                        )}
                      />
                      <Route
                        exact
                        path="/transaction/view/:transactionId"
                        component={componentToFunction(
                          UserOrAdmin(TransactionView)
                        )}
                      />
                      <Route
                        exact
                        path="/invoice/view/:invoiceId"
                        component={componentToFunction(Admin(InvoiceView))}
                      />
                      <Route
                        exact
                        path="/cancelingCertificates/:accountId"
                        component={componentToFunction(
                          UserSeller(CancelingCertificates)
                        )}
                      />
                      <Route
                        exact
                        path="/transferring/:accountId"
                        component={componentToFunction(
                          UserOrAdmin(TransferringCertificates)
                        )}
                      />
                      <Route
                        exact
                        path="/classifiers"
                        component={componentToFunction(Admin(Classifiers))}
                      />
                      <Route
                        exact
                        path="/tradingPlatform/agreements"
                        component={componentToFunction(Admin(TradingPlatform))}
                      />
                      <Route
                        exact
                        path="/tradingPlatform/auctions"
                        component={componentToFunction(Admin(TradingPlatform))}
                      />
                      <Route
                        exact
                        path="/report/grid"
                        component={componentToFunction(Admin(ReportPage))}
                      />
                      <Route
                        exact
                        path="/report/biomethane"
                        component={componentToFunction(
                          UserTraderOrAdmin(ReportPage)
                        )}
                      />
                      <Route
                        exact
                        path="/report/hydrogen"
                        component={componentToFunction(
                          UserTraderOrAdmin(ReportPage)
                        )}
                      />
                      <Route
                        exact
                        path="/report/periodGos"
                        component={componentToFunction(
                          UserProviderOrAdmin(ReportPage)
                        )}
                      />
                      <Route
                        exact
                        path="/report/electricConsumption"
                        component={componentToFunction(
                          UserLoadingOperatorOrAdmin(ReportPage)
                        )}
                      />
                      <Route
                        exact
                        path="/report/go"
                        component={componentToFunction(Admin(ReportPage))}
                      />
                      <Route
                        exact
                        path="/classifiers/:classifierId"
                        component={componentToFunction(Admin(ClassifierView))}
                      />
                      <Route
                        exact
                        path="/classifiers/:classifierId/:subClassifierId"
                        component={componentToFunction(
                          Admin(ClassifierSubView)
                        )}
                      />
                      <Route
                        exact
                        path="/classifiers/:classifierId/:subClassifierId/:subValuesClassifierId"
                        component={componentToFunction(
                          Admin(ClassifierSubValuesView)
                        )}
                      />
                      <Route exact path="/equip" component={AddEquipment} />
                      <Route
                        exact
                        path="/invoice/search"
                        component={componentToFunction(
                          UserProducerOrAdmin(InvoiceSearch)
                        )}
                      />
                      <Route
                        exact
                        path="/consumer/reports"
                        component={componentToFunction(
                          UserConsumer(ConsumerReports)
                        )}
                      />
                      <Route
                        exact
                        path="/consumer/login/:locale?"
                        component={componentToFunction(
                          UserConsumer(ConsumerReports)
                        )}
                      />
                      <Route exact path="/error/403" component={Error403} />
                      <Route
                        exact
                        path="/error/consumer/403"
                        component={componentToFunction(PortletError)}
                      />
                      <Route
                        exact
                        path="/accountGOS/search/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/ts-assignments/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/pre-trading/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/auctions/:registerCode/history"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TsAuctionHistory
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/auctions/:registerCode/view/:auctionId"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TsAuctionDetailsView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/auctions/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/pre-trading/:registerCode/view/:agreementId"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            PreTradingAgreementView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/pre-trading/:registerCode/reserve/:agreementId"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            PreTradingAgreementReservations
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/transactions/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/accountGOS/report/:registerCode"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AccountGOSOverview
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/tradingAccount/transfer/:type"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TradingAccountTransferView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/tradingAccount/addAuction/:type"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            AddTsAuctionView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/tradingAccount/tsAgreement/Registration"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TsAgreementRegistrationView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/tradingAccount/lock/:type"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TradingAccountLockView
                          )
                        )}
                      />
                      <Route
                        exact
                        path="/tradingAccountTransaction/view/:type/:transactionId"
                        component={componentToFunction(
                          UserSellerProviderLoadingOperatorOrAdmin(
                            TransactionGOSView
                          )
                        )}
                      />
                      <Route
                        component={componentToFunction(
                          UserOrAdmin(Notifications)
                        )}
                      />
                    </Switch>
                  </Content>
                </Layout>
              </ConnectedRouter>
            )}
          </ReactReduxContext.Consumer>
        </div>
      </ConfigProvider>
    );
  }
}

const mapStateToProps = state => ({
  locale: getLocale(state),
  personalCode: getUserPersonalCode(state),
});

const mapDispatchToProps = {
  changeLocaleActionCreator,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
