import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import types, * as classificators from '../constants/classificators';
import { translate } from 'react-i18next';
import classifiersStore from '../utils/classifiersStore';
import _ from 'lodash';

const { Option } = Select;

class Classificator extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    value: PropTypes.any,
    allowed: PropTypes.array,
    withUndefinedOption: PropTypes.bool,
  };

  static types = types;

  render() {
    const { type, t, value, allowed, withUndefinedOption } = this.props;
    let options = classificators[type];
    let selectOptions = [];

    if (options) {
      options = _.values(options);

      if (allowed) {
        options = _.filter(options, item => allowed.includes(item));
      }

      selectOptions = _.map(options, key => (
        <Option key={key} value={key}>
          {t(`classificators.${type}.${key}`)}
        </Option>
      ));
    } else {
      options = classifiersStore.getClassifierByType(type);

      if (!options) {
        throw new Error(`There is no classificator ${type} in system`);
      }

      selectOptions = _.map(options, item => (
        <Option
          key={classifiersStore.getCode(item)}
          value={classifiersStore.getCode(item)}
        >
          {classifiersStore.getName(item)}
        </Option>
      ));
    }

    const undefinedOption = (
      <Option key="1" value={null}>
        -
      </Option>
    );

    return (
      <Select
        {...this.props}
        dropdownMatchSelectWidth={false}
        value={value || undefined}
      >
        {withUndefinedOption
          ? [undefinedOption, ...selectOptions]
          : selectOptions}
      </Select>
    );
  }
}

export default translate('common')(Classificator);
