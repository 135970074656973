import {
  createValidation,
  validatorRequired,
} from '../../utils/formValidators';

import { meteringPointFields } from '../../constants/meteringPoint';

export default createValidation([
  {
    name: meteringPointFields.GAS,
    validators: [validatorRequired()],
  },
  {
    name: meteringPointFields.CONSUMPTION_TYPE,
    validators: validatorRequired(),
  },
  {
    name: meteringPointFields.START_DATE,
    validators: validatorRequired(),
  },
]);
