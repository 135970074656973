import {
  BIOMETHANE_REPORT_GO_ITEMS_FETCH,
  HYDROGEN_REPORT_GO_ITEMS_FETCH,
} from '../constants/actionTypes/reportGoActionTypes';
import {listInitialAsyncPagingState, createAsyncPagingReducer} from '../utils/asyncPagingHelpers';
import {createAction, handleActions} from 'redux-actions';
import { combineReducers } from 'redux-immutable';

export const goBiomethaneReportResetMaster = createAction('GO_BIOMETHANE_REPORT_RESET_MASTER');
export const goHydrogenReportResetMaster = createAction('GO_HYDROGEN_REPORT_RESET_MASTER');

const biomethaneInitialState = listInitialAsyncPagingState;
const hydrogenInitialState = listInitialAsyncPagingState;

const biomethaneReportReducer = handleActions(
  {
    ...createAsyncPagingReducer(BIOMETHANE_REPORT_GO_ITEMS_FETCH),
    [goBiomethaneReportResetMaster]: state => state.merge(biomethaneInitialState),
  },
  biomethaneInitialState,
);

const hydrogenReportReducer = handleActions(
  {
    ...createAsyncPagingReducer(HYDROGEN_REPORT_GO_ITEMS_FETCH),
    [goHydrogenReportResetMaster]: state => state.merge(hydrogenInitialState),
  },
  hydrogenInitialState,
);

export const getBiomethaneReport = state => state.get('reportGo').get('biomethaneReport');

export const getHydrogenReport = state => state.get('reportGo').get('hydrogenReport');

const reportGoReducer = combineReducers({
  biomethaneReport: biomethaneReportReducer,
  hydrogenReport: hydrogenReportReducer,
});

export default reportGoReducer;
