import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  transactionFields,
  transactionGOInfoFields,
} from '../../constants/transaction';
import {
  translateFuelTypeClassificator,
  translateHydrogenTechnologyClassificator,
} from '../../utils/translateHelpers';

class TransactionGO extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t, info } = this.props;

    const goInfo = info[transactionFields.GO_INFORMATION];
    const isHydrogen = goInfo.gas === 'HYDROGEN';
    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('go.amount')}: </b>
            {goInfo[transactionGOInfoFields.GO_AMOUNT]}
          </Col>
          <Col span={8}>
            <b>{t('go.startBalance')}: </b>
            {goInfo[transactionGOInfoFields.START_BALANCE]}
          </Col>
          <Col span={8}>
            <b>{t('go.endBalance')}: </b>
            {goInfo[transactionGOInfoFields.END_BALANCE]}
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('go.period')}: </b>
            {goInfo[transactionGOInfoFields.ISSUE_PERIOD]}
          </Col>
          <Col span={8}>
            <b>{t('go.eicx')}: </b>
            {goInfo[transactionGOInfoFields.UNIT_EICX]}
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{isHydrogen ? t('go.hydrogenTechnology') : t('go.biomethaneTechnology')}: </b>
            {isHydrogen ? translateHydrogenTechnologyClassificator(goInfo[transactionGOInfoFields.TECHNOLOGY]) : goInfo[transactionGOInfoFields.TECHNOLOGY]}
          </Col>
          <Col span={8}>
            <b>{isHydrogen ? t('go.hydrogenFuel') : t('go.biomethaneFuel')}: </b>
            {isHydrogen ? t('go.hydrogenType.' + goInfo[transactionGOInfoFields.HYDROGEN_FUEL_TYPE]) : translateFuelTypeClassificator(
              goInfo[transactionGOInfoFields.FUEL_TYPE]
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TransactionGO;
