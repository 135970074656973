import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { accountTableFields } from '../../constants/accountTable';
import { Button, Icon, Table } from 'antd';
import _ from 'lodash/fp';
import {
  translateFuelTypeClassificator,
  translateCleaningTechnologyClassificator,
  translateGasTypeClassificator,
  translateHydrogenTechnologyTypeClassificator,
  translateHydrogenFuelTypeClassificator,
} from '../../utils/translateHelpers';
import { TableActions } from '../';
import moment from 'moment/moment';
import './AccountTable.css';
import gasType from '../../constants/classificators/gasType';

const hasField = field => _.flow(_.first, _.get(field), _.isEmpty, x => !x);

class AccountTable extends Component {
  static propTypes = {
    dataList: PropTypes.array,
    t: PropTypes.func.isRequired,
    accountIds: PropTypes.array.isRequired,
    transferCertificates: PropTypes.func.isRequired,
    cancelCertificates: PropTypes.func.isRequired,
    isUserSeller: PropTypes.bool,
  };

  state = {
    selectedRows: [],
  };

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.dataList, this.props.dataList)) {
      this.setState({ selectedRows: [] });
    }
  }

  toggleSelectAllCheckbox = isHidden => {
    let style = document.getElementById('selectAllCheckboxStyle');

    if (!style) {
      style = document.createElement('style');
      style.id = 'selectAllCheckboxStyle';
      document.head.appendChild(style);
    }

    style.innerHTML = `
        .ant-table-thead tr .ant-table-selection-column div {
            display: 'block';
        }
    `;
  };

  renderExpirationTimeOrSummary = (value, record, t, other) => {
    if (record.summary === true)
      return (
        <>
          {record.biomethaneQuantity !== 0 && (
            <b className={'summaryText'}> {t('table.totalBiomethane')}</b>
          )}
          {record.hydrogenQuantity !== 0 && (
            <b className={'summaryText'}> {t('table.totalHydrogen')}</b>
          )}
        </>
      );
    else if (other) {
      return other(value);
    } else return value;
  };

  renderQuantityOrSummary = (value, record) => {
    if (record.summary === true)
      return (
        <>
          {record.biomethaneQuantity !== 0 && (
            <b className={'summaryAmount'}> {record.biomethaneQuantity}</b>
          )}
          {record.hydrogenQuantity !== 0 && (
            <b className={'summaryAmount'}> {record.hydrogenQuantity}</b>
          )}
        </>
      );
    return value;
  };

  getColumns = () => {
    const { t, dataList, fieldsIncludes } = this.props;

    const hasBiomethane = dataList.some(
      e => e.gasType === gasType.BIOMETHANE_GAS_TYPE
    );
    const hasHydrogen = dataList.some(
      e => e.gasType === gasType.HYDROGEN_GAS_TYPE
    );
    this.toggleSelectAllCheckbox(hasBiomethane && hasHydrogen);

    const columns = [];
    columns.push(
      {
        title: t('table.gas'),
        dataIndex: accountTableFields.GAS_TYPE,
        render: translateGasTypeClassificator,
      },
      {
        title: t('table.productionTime'),
        dataIndex: accountTableFields.PRODUCTION_TIME,
        render: value => {
          if (typeof value !== 'undefined') {
            return moment(value, 'DD/MM/YYYY').format('MM.YYYY');
          }
        },
      },
      {
        title: t('table.transferDeadline'),
        dataIndex: accountTableFields.TRANSFER_DEADLINE,
      }
    );

    if (
      hasField(accountTableFields.EXPIRATION_TIME)(dataList) &&
      !hasField(accountTableFields.HYDROGEN_FUEL_TYPE)(dataList) &&
      !hasField(accountTableFields.HYDROGEN_TECHNOLOGY)(dataList) &&
      !hasField(accountTableFields.FUEL_TYPE)(dataList) &&
      !hasField(accountTableFields.TECHNOLOGY)(dataList)
    ) {
      columns.push({
        title: t('table.expirationTime'),
        dataIndex: accountTableFields.EXPIRATION_TIME,
        // Total quantity summary
        render: (value, record) =>
          this.renderExpirationTimeOrSummary(value, record, t, null),
      });
    } else {
      columns.push({
        title: t('table.expirationTime'),
        dataIndex: accountTableFields.EXPIRATION_TIME,
      });
    }

    if (
      (hasField(accountTableFields.HYDROGEN_FUEL_TYPE)(dataList) &&
        !hasField(accountTableFields.HYDROGEN_TECHNOLOGY)(dataList)) ||
      (dataList.length === 0 &&
        fieldsIncludes(accountTableFields.HYDROGEN_FUEL_TYPE))
    ) {
      columns.push({
        title: t('table.hydrogenFuelType'),
        dataIndex: accountTableFields.HYDROGEN_FUEL_TYPE,
        render: (value, record) =>
          this.renderExpirationTimeOrSummary(
            value,
            record,
            t,
            translateHydrogenFuelTypeClassificator
          ),
      });
    } else if (hasField(accountTableFields.HYDROGEN_FUEL_TYPE)(dataList)) {
      columns.push({
        title: t('table.hydrogenFuelType'),
        dataIndex: accountTableFields.HYDROGEN_FUEL_TYPE,
        render: translateHydrogenFuelTypeClassificator,
      });
    }

    if (
      hasField(accountTableFields.HYDROGEN_TECHNOLOGY)(dataList) ||
      (dataList.length === 0 &&
        fieldsIncludes(accountTableFields.HYDROGEN_TECHNOLOGY))
    ) {
      columns.push({
        title: t('table.hydrogenTechnologyType'),
        dataIndex: accountTableFields.HYDROGEN_TECHNOLOGY,
        render: (value, record) =>
          this.renderExpirationTimeOrSummary(
            value,
            record,
            t,
            translateHydrogenTechnologyTypeClassificator
          ),
      });
    }

    if (
      (hasField(accountTableFields.FUEL_TYPE)(dataList) &&
        !hasField(accountTableFields.TECHNOLOGY)(dataList)) ||
      (dataList.length === 0 && fieldsIncludes(accountTableFields.FUEL_TYPE))
    ) {
      columns.push({
        title: t('table.fuelType'),
        dataIndex: accountTableFields.FUEL_TYPE,
        render: (value, record) =>
          this.renderExpirationTimeOrSummary(
            value,
            record,
            t,
            translateFuelTypeClassificator
          ),
      });
    } else if (hasField(accountTableFields.FUEL_TYPE)(dataList)) {
      columns.push({
        title: t('table.fuelType'),
        dataIndex: accountTableFields.FUEL_TYPE,
        render: translateFuelTypeClassificator,
      });
    }

    if (
      hasField(accountTableFields.TECHNOLOGY)(dataList) ||
      (dataList.length === 0 && fieldsIncludes(accountTableFields.TECHNOLOGY))
    ) {
      columns.push({
        title: t('table.technology'),
        dataIndex: accountTableFields.TECHNOLOGY,
        render: (value, record) =>
          this.renderExpirationTimeOrSummary(
            value,
            record,
            t,
            translateCleaningTechnologyClassificator
          ),
      });
    }

    columns.push({
      title: t('table.quantity'),
      dataIndex: accountTableFields.QUANTITY,
      // Total quantity summary
      render: (value, record) => this.renderQuantityOrSummary(value, record),
    });

    columns.push({
      title: '',
      dataIndex: accountTableFields.TRANSFER_DEADLINE_PASSED,
      render: value => {
        if (!value) {
          return null;
        }

        return (
          <Icon
            title={t('deadlinePassed')}
            type="info-circle"
            className={'danger-icon'}
          />
        );
      },
    });

    return columns;
  };

  rowSelectionRegular = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows: selectedRows });
    },
    getCheckboxProps: record => {
      return {
        disabled: record.summary === true,
        summary: record.summary,
      };
    },
  };

  rowSelectionMixed = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ selectedRows: selectedRows });
    },
    columnTitle: (
      <div className={'ant-table-selection'}>
        <label className={'ant-checkbox-wrapper ant-checkbox-wrapper-disabled'}>
          <span className={'ant-checkbox ant-checkbox-disabled'}>
            <input
              type="checkbox"
              className={'ant-checkbox-input'}
              disabled
              value=""
            />
            <span className={'ant-checkbox-inner'} />
          </span>
        </label>
      </div>
    ),
    // Disable last table row selection
    getCheckboxProps: record => {
      const hydrogenSelected = this.state.selectedRows.some(
        e => e.gasType === gasType.HYDROGEN_GAS_TYPE
      );
      const biomethaneSelected = this.state.selectedRows.some(
        e => e.gasType === gasType.BIOMETHANE_GAS_TYPE
      );

      if (hydrogenSelected) {
        return {
          disabled:
            record.gasType === gasType.BIOMETHANE_GAS_TYPE ||
            record.summary === true,
        };
      }
      if (biomethaneSelected) {
        return {
          disabled:
            record.gasType === gasType.HYDROGEN_GAS_TYPE ||
            record.summary === true,
        };
      }
      return {
        disabled: record.summary === true,
        summary: record.summary,
      };
    },
  };

  render() {
    const {
      dataList,
      t,
      transferCertificates,
      cancelCertificates,
      manufacture,
    } = this.props;
    const { selectedRows } = this.state;
    const hasBiomethane = dataList.some(
      e => e.gasType === gasType.BIOMETHANE_GAS_TYPE
    );
    const hasHydrogen = dataList.some(
      e => e.gasType === gasType.HYDROGEN_GAS_TYPE
    );
    const checkAllIsDisabled = hasBiomethane && hasHydrogen;

    const isDeadlinePassed = !!(this.state.selectedRows || []).find(
      item => item.transferDeadlinePassed
    );

    return (
      <React.Fragment>
        <Table
          rowKey={accountTableFields.GO_SEARCH_RESPONSE_ROW_ID}
          dataSource={dataList}
          columns={this.getColumns()}
          pagination={false}
          rowSelection={
            checkAllIsDisabled
              ? this.rowSelectionMixed
              : this.rowSelectionRegular
          }
        />
        {selectedRows.length > 0 && (
          <TableActions>
            <Button
              type="primary"
              disabled={isDeadlinePassed}
              title={isDeadlinePassed ? t('deadlinePassed') : ''}
              onClick={() => transferCertificates(selectedRows)}
            >
              {t('buttonTransfer')}
            </Button>
            {!manufacture && (
              <Button
                type="primary"
                onClick={() => cancelCertificates(selectedRows)}
                className="global-margin-left-10"
              >
                {t('buttonCancel')}
              </Button>
            )}
          </TableActions>
        )}
      </React.Fragment>
    );
  }
}

export default AccountTable;
