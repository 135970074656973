import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { getLoadingSelector } from '../../utils/asyncHelpers';
import {
  goToTransferring,
  goToCancelingCertificates,
} from '../../utils/gotoLink';
import { AccountTable } from '../../components';
import * as actions from '../../actions/actionCreators/accountActionCreators';
import { getUserLegalEntityAccounts } from '../../reducers/userReducer';
import {
  getOverviewList,
  getOverviewListData,
  getGroupedCertificates,
} from '../../reducers/accountOverviewReducer';
import { formAccountOverviewFilter } from '../../constants/forms/formAccountOverviewFilter';
import gasType from '../../constants/classificators/gasType';

export class AccountSummary extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    fetchGroupedCertificatesAndTransfer: PropTypes.func.isRequired,
    fetchGroupedCertificatesAndCancel: PropTypes.func.isRequired,
    dataList: PropTypesImmutable.list.isRequired,
    accountIds: PropTypes.array,
  };

  render() {
    const {
      isLoading,
      t,
      dataList,
      fetchGroupedCertificatesAndTransfer,
      fetchGroupedCertificatesAndCancel,
      accountIds,
      userAccounts,
      manufacture,
      fieldsIncludes,
    } = this.props;

    // Add total quantity as the last element of json
    let data = dataList.toJS();
    if (data.length > 0) {
      let biomethaneTotalQuantity = data
        .filter(row => row.gasType === gasType.BIOMETHANE_GAS_TYPE)
        .map(row => row.quantity)
        .reduce((acc, amount) => acc + amount, 0);
      let hydrogenTotalQuantity = data
        .filter(row => row.gasType === gasType.HYDROGEN_GAS_TYPE)
        .map(row => row.quantity)
        .reduce((acc, amount) => acc + amount, 0);

      data.push({
        biomethaneQuantity: biomethaneTotalQuantity,
        hydrogenQuantity: hydrogenTotalQuantity,
        summary: true,
      });
    }

    return (
      <Spin spinning={isLoading}>
        <AccountTable
          t={t}
          accountIds={accountIds}
          dataList={data}
          transferCertificates={fetchGroupedCertificatesAndTransfer}
          cancelCertificates={fetchGroupedCertificatesAndCancel}
          userAccounts={userAccounts}
          manufacture={manufacture}
          fieldsIncludes={fieldsIncludes}
        />
      </Spin>
    );
  }
}

const getLoading = getLoadingSelector(getOverviewList, getGroupedCertificates);

const mapStateToProps = state => ({
  isLoading: getLoading(state),
  dataList: getOverviewListData(state),
  userAccounts: getUserLegalEntityAccounts(state),
  info: state,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchGroupedCertificatesAndTransfer: async rows => {
    await dispatch(
      actions.fetchGroupedCertificates({
        [formAccountOverviewFilter.SELECTED_ROWS]: rows,
        [formAccountOverviewFilter.ACCOUNT_IDS]: ownProps.accountIds,
      })
    );
    goToTransferring(ownProps.accountIds);
  },
  fetchGroupedCertificatesAndCancel: async rows => {
    await dispatch(
      actions.fetchCancelGroupedCertificates({
        [formAccountOverviewFilter.SELECTED_ROWS]: rows,
        [formAccountOverviewFilter.ACCOUNT_IDS]: ownProps.accountIds,
      })
    );
    goToCancelingCertificates(ownProps.accountIds);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSummary);
