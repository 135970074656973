import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  transactionFields,
  transactionAccountFields,
} from '../../constants/transaction';
import { TableRowActions } from '../';
import { Table, Button, Spin, Modal } from 'antd';
import {
  translateGasClassificator,
  translateTransactionTypeClassificator,
} from '../../utils/translateHelpers';
import { defaultDateFormater } from '../../utils';
import { goToTransactionView } from '../../utils/gotoLink';
import {
  isDisabledRecallButton,
  hasRecallButton,
  hasSendVdsButton,
} from '../../utils/transactionHelpers';
import { VDSMessageModal } from '../../forms';

export class TransactionTable extends Component {
  static propTypes = {
    transactions: PropTypes.array,
    totalElements: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    searchTransactions: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    pageSize: PropTypes.number.isRequired,
    userRole: PropTypes.string.isRequired,
  };

  state = {
    vdsMessageModalVisible: false,
    vdsMessageForm: {},
    transactionId: null,
  };

  onPageChange = page => {
    const { searchTransactions } = this.props;

    searchTransactions(page);
  };

  getColumns = () => {
    const { t, userRole } = this.props;

    return [
      {
        title: t('table.type'),
        dataIndex: transactionFields.TYPE,
        render: translateTransactionTypeClassificator,
      },
      {
        title: t('table.gas'),
        dataIndex: transactionFields.GAS,
        render: translateGasClassificator,
      },
      {
        title: t('table.goAmount'),
        dataIndex: transactionFields.GO_AMOUNT,
      },
      {
        title: t('table.senderAccount'),
        dataIndex: `${transactionFields.SENDER_ACCOUNT}.${
          transactionAccountFields.COMPANY
        }`,
      },
      {
        title: t('table.receiverAccount'),
        dataIndex: `${transactionFields.RECEIVER_ACCOUNT}.${
          transactionAccountFields.COMPANY
        }`,
      },
      {
        title: t('table.createTime'),
        dataIndex: transactionFields.CREATE_TIME,
        render: defaultDateFormater,
      },
      {
        title: t('table.actions'),
        dataIndex: transactionFields.ID,
        width: 140,
        render: (value, values) => (
          <TableRowActions>
            {hasRecallButton(
              userRole,
              values[transactionFields.STATUS],
              values[transactionFields.TYPE],
              true
            ) && (
              <React.Fragment>
                <Button
                  shape="circle"
                  icon="rollback"
                  size="small"
                  type="primary"
                  title={t('table.recallBtn')}
                  disabled={isDisabledRecallButton(
                    values[transactionFields.STATUS],
                    true
                  )}
                  onClick={() => goToTransactionView(value)}
                />
                <span className="ant-divider" />
              </React.Fragment>
            )}
            <Button
              shape="circle"
              icon="select"
              size="small"
              type="primary"
              title={t('table.viewBtn')}
              onClick={() => goToTransactionView(value)}
            />
            {hasSendVdsButton(
              userRole,
              values[transactionFields.CAN_SEND_VDS_REQUEST]
            ) && (
              <React.Fragment>
                <span className="ant-divider" />
                <Button
                  shape="circle"
                  icon="retweet"
                  size="small"
                  type="danger"
                  title={t('table.sendVdsBtn')}
                  onClick={() => {
                    this.showVDSMessageModal(values[transactionFields.ID]);
                  }}
                />
                <span className="ant-divider" />
              </React.Fragment>
            )}
          </TableRowActions>
        ),
      },
    ];
  };

  showVDSMessageModal = transactionId => {
    this.setState({ transactionId: transactionId });
    this.setState({ vdsMessageModalVisible: true });
  };

  hideVDSMessageModal = () => {
    this.setState({ vdsMessageModalVisible: false });
    this.setState({ transactionId: null });
  };

  render() {
    const {
      t,
      transactions,
      totalElements,
      loading,
      page,
      pageSize,
      searchTransactions,
    } = this.props;
    const { vdsMessageModalVisible, transactionId } = this.state;
    return (
      <div>
        <Spin spinning={loading} className="search-results-container">
          <Table
            rowKey={transactionFields.ID}
            dataSource={transactions}
            columns={this.getColumns()}
            pagination={{
              onChange: this.onPageChange,
              current: page,
              total: totalElements,
              pageSize,
              defaultCurrent: 1,
            }}
          />
        </Spin>
        {vdsMessageModalVisible && (
          <Modal
            title={t('vdsMessageModalTitle')}
            visible
            footer={false}
            width="1200px"
            onCancel={this.hideVDSMessageModal}
          >
            <VDSMessageModal
              transactionId={transactionId}
              transactionType={'B'}
              vdsMessageType={'BiofuelToReportRequest'}
              onClose={() => {
                this.hideVDSMessageModal();
                searchTransactions(page);
              }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default TransactionTable;
