import transactionTypes from '../constants/classificators/transactionType';
import transactionStatuses from '../constants/classificators/transactionStatus';
import { isAdmin } from './roles';

export const hasRecallButton = (
  role,
  transactionStatus,
  transactionType,
  canRecall
) =>
  isAdmin(role) &&
  transactionType === transactionTypes.INCOMING &&
  (transactionStatus === transactionStatuses.SUCCEEDED ||
    transactionStatus === transactionStatuses.WAITING ||
    transactionStatus === transactionStatuses.LOCKED) &&
  canRecall;

export const hasSendVdsButton = (role, canSendVdsRequest) =>
  isAdmin(role) && canSendVdsRequest;

/**
 * CANCELLING to transportaion must be recalled via TransactionForGOS API
 * @param {*} role
 * @param {*} transactionStatus
 * @param {*} transactionType
 */
export const hasRecallGuideText = (role, transactionStatus, transactionType) =>
  isAdmin(role) &&
  transactionType === transactionTypes.CANCELLATION &&
  (transactionStatus === transactionStatuses.SUCCEEDED ||
    transactionStatus === transactionStatuses.WAITING ||
    transactionStatus === transactionStatuses.LOCKED);

export const isDisabledRecallButton = (transactionStatus, canRecall) =>
  transactionStatus === transactionStatuses.LOCKED || !canRecall;

export const hasGenerateButton = (role, transactionStatus, transactionType) =>
  isAdmin(role) &&
  transactionType !== transactionTypes.ISSUE &&
  transactionStatus === transactionStatuses.REVOKED;

/* GOS transactions */

export const hasGosRecallButton = (role, transactionStatus, transactionType) =>
  isAdmin(role) &&
  (transactionType === transactionTypes.ISSUE ||
    transactionType === transactionTypes.INCOMING ||
    transactionType === transactionTypes.OUTGOING ||
    transactionType === transactionTypes.LOCKING) &&
  (transactionStatus === transactionStatuses.SUCCEEDED ||
    transactionStatus === transactionStatuses.WAITING);
