import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { consumerFields } from '../../constants/consumer';
import { Table, Button } from 'antd';
import { translateCustomerTypeClassificator } from '../../utils/translateHelpers';
import { consumerType } from '../../constants/classificators';
import { NAMESPACES } from '../../i18n';
import { translate } from 'react-i18next';

class ConsumerInfoTable extends Component {
  static propTypes = {
    consumer: PropTypes.object,
    t: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  getColumns = () => {
    const { t, onEdit, isAdmin } = this.props;

    const columns = [
      {
        title: t('table.name'),
        dataIndex: consumerFields.NAME,
      },
      {
        title: t('table.type'),
        dataIndex: consumerFields.REGISTER_CODE,
        render: value =>
          value
            ? translateCustomerTypeClassificator(consumerType.MANUFACTURER)
            : translateCustomerTypeClassificator(consumerType.CONSUMER),
      },
      {
        title: t('table.code'),
        dataIndex: consumerFields.EICX_CODE,
      },
      {
        title: t('table.actions'),
        dataIndex: consumerFields.ID,
        render: (value, values) => (
          <Button disabled={!isAdmin} onClick={() => onEdit(values)}>
            {t('table.editBtn')}
          </Button>
        ),
      },
    ];

    return columns;
  };

  render() {
    const { consumer } = this.props;

    const data = consumer ? [consumer] : [];

    return (
      <Table
        rowKey={consumerFields.ID}
        dataSource={data}
        columns={this.getColumns()}
        pagination={false}
      />
    );
  }
}

export default translate(NAMESPACES.consumer)(ConsumerInfoTable);
