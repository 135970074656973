import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col} from 'antd';
import {
  transactionFields,
  transactionConsumerFields,
} from '../../constants/transaction';

class TransactionConsumerInfo extends React.Component {
  static propTypes = {
    info: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const {t, info} = this.props;

    const consumerInfo = info[transactionFields.CONSUMER_INFORMATION];
    let consumerType = '';
    if (consumerInfo[transactionConsumerFields.CONSUMER_TYPE] === 'COMPANY') {
      consumerType = 'Company'
    }
    if (consumerInfo[transactionConsumerFields.CONSUMER_TYPE] === 'PRIVATE') {
      consumerType = 'Physical person'
    }

    return (
      <React.Fragment>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('consumer.name')}: </b>
            {consumerInfo[transactionConsumerFields.NAME]}
          </Col>
          <Col span={8}>
            <b>{t('consumer.code')}: </b>
            {consumerInfo[transactionConsumerFields.CODE]}
          </Col>
        </Row>
        <Row className="global-margin-bottom-10">
          <Col span={8}>
            <b>{t('consumer.eicx')}: </b>
            {consumerInfo[transactionConsumerFields.EICX]}
          </Col>
          <Col span={8}>
            <b>{t('consumer.type')}: </b>
            {consumerType}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TransactionConsumerInfo;
