import React, { Component } from 'react';
import moment from 'moment';
import { reduxForm, Field, Fields, formValueSelector } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { ClassificatorField, DateRangeField, NumberField } from '../../atoms';
import { FormActions, AutoCompleteAccountsField } from '../../components';
import { connect } from 'react-redux';
import { FORM_TRANSACTION_SEARCH } from '../../constants/formKeys';
import { formTransactionSearchFields } from '../../constants/forms/formTransactionSearch';
import { Collapse, Row, Col, Button } from 'antd';
import * as actions from '../../actions/actionCreators/transactionSearchActionCreators';
import { gas } from '../../constants/classificators';
import { legalEntityFields } from '../../constants/legalEntity';

const { Panel } = Collapse;

export class TransactionFilter extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    searchTransactions: PropTypes.func.isRequired,
    accountId: PropTypes.string,
  };
  componentDidMount = () => this.props.searchTransactions();

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.searchTransactions();
    }
  }

  onClear = () => {
    const { submit, reset } = this.props;

    reset();
    submit();
  };

  render() {
    const { t, handleSubmit, gasFieldValue } = this.props;
    NumberField.defaultProps.precision=0;
    return (
      <div>
        <div className="search-form-container">
          <Collapse defaultActiveKey={['filters']}>
            <Panel key="filters" header={t('filter.panelTitle')}>
              <form onSubmit={handleSubmit}>
                <Row gutter={32}>
                  <Col span={16}>
                    <Fields
                      label={t('filter.dateRange.title')}
                      names={[
                        formTransactionSearchFields.DATE_FROM,
                        formTransactionSearchFields.DATE_TO,
                      ]}
                      placeholder={[
                        t('filter.dateRange.dateFromPlaceholder'),
                        t('filter.dateRange.dateToPlaceholder'),
                      ]}
                      component={DateRangeField}
                      hasFeedback={false}
                      allowClear={false}
                      ranges={{
                        [t('filter.dateRange.currentMonth')]: [
                          moment().startOf('month'),
                          moment().endOf('month'),
                        ],
                        [t('filter.dateRange.beginLastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment(),
                        ],
                        [t('filter.dateRange.lastMonth')]: [
                          moment()
                            .subtract(1, 'months')
                            .startOf('month'),
                          moment()
                            .subtract(1, 'months')
                            .endOf('month'),
                        ],
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.transactionType')}
                      name={formTransactionSearchFields.TRANSACTION_TYPE}
                      component={ClassificatorField}
                      type={ClassificatorField.types.transactionType}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.receiverAccount')}
                      name={formTransactionSearchFields.RECEIVER_ACCOUNT_NUMBER}
                      component={AutoCompleteAccountsField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.senderAccount')}
                      name={formTransactionSearchFields.SENDER_ACCOUNT_NUMBER}
                      component={AutoCompleteAccountsField}
                      hasFeedback={false}
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      label={t('filter.number')}
                      name={formTransactionSearchFields.NUMBER}
                      component={NumberField}
                      hasFeedback={false}
                    />
                  </Col>
                </Row>
                <Row gutter={32}>
                  <Col span={8}>
                    <Field
                      label={t('filter.gas')}
                      name={formTransactionSearchFields.GAS}
                      component={ClassificatorField}
                      type={ClassificatorField.types.gas}
                      hasFeedback={false}
                    />
                  </Col>
                  {gasFieldValue === gas.BIOMETHANE && (
                    <>
                      <Col span={8}>
                        <Field
                          label={t('filter.fuelType')}
                          name={formTransactionSearchFields.FUEL_TYPE}
                          component={ClassificatorField}
                          type={ClassificatorField.types.fuelType}
                          hasFeedback={false}
                        />
                      </Col>
                      <Col span={8}>
                        <Field
                          label={t('filter.cleaningTechnologyType')}
                          name={formTransactionSearchFields.CLEANING_TECHNOLOGY_TYPE}
                          component={ClassificatorField}
                          type={ClassificatorField.types.cleaningTechnologyType}
                          hasFeedback={false}
                        />
                      </Col>
                    </>
                  )}
                  {gasFieldValue === gas.HYDROGEN && (
                    <>
                      <Col span={8}>
                        <Field
                          label={t('filter.hydrogenType')}
                          name={formTransactionSearchFields.HYDROGEN_TYPE}
                          component={ClassificatorField}
                          type={ClassificatorField.types.hydrogenType}
                          hasFeedback={false}
                        />
                      </Col>
                      <Col span={8}>
                        <Field
                          label={t('filter.hydrogenManufacturingTechnology')}
                          name={formTransactionSearchFields.HYDROGEN_MANUFACTURING_TECHNOLOGY}
                          component={ClassificatorField}
                          type={ClassificatorField.types.hydrogenTechnologyType}
                          hasFeedback={false}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <FormActions>
                  <Button onClick={this.onClear}>{t('filter.clearBtn')}</Button>
                  <Button type="primary" htmlType="submit">
                    {t('filter.submitBtn')}
                  </Button>
                </FormActions>
              </form>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}

const onSubmit = (values, dispatch) => dispatch(actions.searchTransactions());

const mapDispatchToProps = {
  ...actions,
};
const selector = formValueSelector(FORM_TRANSACTION_SEARCH);

function determineGasTypeAndAccountIds(ownProps) {
  let gasValue = null;
  let producerIds = null;
  const legalEntity = ownProps.legalEntity;
  if (legalEntity) {
    if (ownProps.accountId === 'manufacture'){
      const manufacturerAccountTypes = ['MANUFACTURER', 'HYDROGEN_MANUFACTURER'];
      producerIds = legalEntity.get('accounts')
        .filter(account => manufacturerAccountTypes.includes(account.get('accountType')))
        .map(account => account.get('id'));
    } else {
      producerIds = [ownProps.accountId];
    }
    const isHydrogenManufacturer = legalEntity.get(legalEntityFields.HYDROGEN_MANUFACTURER);
    const isBiomethaneManufacturer = legalEntity.get(legalEntityFields.MANUFACTURER);
    const isTrader = legalEntity.get('accounts')
      .some(account => producerIds.includes(account.get('id')+'') && account.get('accountType') === 'TRADER');
    if (!isTrader) {
      if (isHydrogenManufacturer && !isBiomethaneManufacturer) {
        gasValue = gas.HYDROGEN;
      } else if (!isHydrogenManufacturer && isBiomethaneManufacturer) {
        gasValue = gas.BIOMETHANE;
      }
    }
  }
  return { producerIds, gasValue };
}

const mapStateToProps = (state, ownProps) => {
  const values = determineGasTypeAndAccountIds(ownProps);
  const producerIds = values.producerIds;
  const gasValue = values.gasValue;
  return {
    initialValues: {
      [formTransactionSearchFields.CURRENT_ACCOUNT_ID]: producerIds,
      [formTransactionSearchFields.GAS]: gasValue,
    },
    gasFieldValue: selector(state, formTransactionSearchFields.GAS),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_TRANSACTION_SEARCH,
    onSubmit,
    enableReinitialize: true,
  })(TransactionFilter),
);
