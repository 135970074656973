export const formTransactionGosSearchFields = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
  TRANSACTION_TYPE: 'transactionType',
  RECEIVER_ACCOUNT_NUMBER: 'receiverAccountId',
  SENDER_ACCOUNT_NUMBER: 'senderAccountId',
  NUMBER: 'id',
  CURRENT_ACCOUNT_ID: 'currentAccountId',
  TRANSACTION_STATUS: 'transactionStatus',
};
