import React from 'react';
import {connect} from 'react-redux';
import {Spin, Tabs} from 'antd';
import {translate} from 'react-i18next';
import {NAMESPACES} from '../../i18n';
import {TransactionFilter, TransactionGosFilter} from '../../forms';
import {TransactionList, TransactionGosList} from '../';
import PropTypes from 'prop-types';
import { getRole, getUserLegalEntity } from '../../reducers/userReducer';
import {isAdmin} from '../../utils/roles';
import {currentPathSelector} from '../../utils/gotoLink';

const {TabPane} = Tabs;

const tabKeys = {
  GO: 'go',
  GOS: 'gos',
};

class TransactionSearch extends React.Component {

  static propTypes = {
    t: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    userRole: PropTypes.string,
    currentPath: PropTypes.string.isRequired,
  };

  getActiveTab = () => {
    const {currentPath} = this.props;

    if (currentPath.includes('/gos')) {
      return tabKeys.GOS;
    }
    return tabKeys.GO;
  };

  render() {
    const {t, accountId, isLoading, userRole, legalEntity, ...rest} = this.props;
    return (
      <Spin spinning={isLoading}>
        <div className="main-container">
          <h1>{t('title')}</h1>
          <Tabs type="card" defaultActiveKey={this.getActiveTab()}>
            <TabPane tab={t('tabs.transactionGoTabTitle')} key={tabKeys.GO}>
              <TransactionFilter accountId={accountId} legalEntity={legalEntity} t={t} {...rest} />
              <TransactionList t={t} {...rest} />
            </TabPane>

            {isAdmin(userRole) && (
              <TabPane tab={t('tabs.transactionGosTabTitle')} key={tabKeys.GOS}>
                <TransactionGosFilter accountId={accountId} t={t} {...rest} />
                <TransactionGosList t={t} {...rest} />
              </TabPane>
            )}
          </Tabs>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: false,
  userRole: getRole(state),
  currentPath: currentPathSelector(state),
  legalEntity: getUserLegalEntity(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(
  translate(NAMESPACES.transactionSearch)(TransactionSearch),
);
