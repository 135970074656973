import {createAsyncPagingAction} from '../../utils/asyncPagingHelpers.js';
import * as actionTypes from '../../constants/actionTypes/reportGoActionTypes';
import {biomethaneReportGoItemsFetch, hydrogenReportGoItemsFetch} from '../actionHelpers/endpoints';
import { goBiomethaneReportResetMaster, goHydrogenReportResetMaster } from '../../reducers/reportGoReducer';

export const fetchBiomethaneReportGoItemsPage = createAsyncPagingAction(
  actionTypes.BIOMETHANE_REPORT_GO_ITEMS_FETCH,
  biomethaneReportGoItemsFetch
);

export const fetchHydrogenReportGoItemsPage = createAsyncPagingAction(
  actionTypes.HYDROGEN_REPORT_GO_ITEMS_FETCH,
  hydrogenReportGoItemsFetch
);

export const goBiomethaneReportResetMasterActionCreator = () => dispatch =>
  dispatch(goBiomethaneReportResetMaster());

export const goHydrogenReportResetMasterActionCreator = () => dispatch =>
  dispatch(goHydrogenReportResetMaster());
