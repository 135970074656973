export const goReportFields = {
  ID: 'id',
  TRANSACTION_DATE: 'transactionDate',
  TRANSACTION_NUMBER: 'transactionNumber',
  TRANSACTION_STATUS: 'transactionStatus',
  CANCELLED_GO_COUNT: 'cancelledGoCount',
  NATURAL_ENERGY_AMOUNT: 'naturalEnergyAmount',
  CALCULATED_MULTIPLIER: 'calculatedMultiplier',
  CALCULATED_ENERGY_AMOUNT: 'calculatedEnergyAmount',
  EICW_CODE: 'eicwCode',
  PRODUCTION_PERIOD: 'productionPeriod',
  CONSUMPTION_PERIOD: 'consumptionPeriod',
  POS_NUMBER: 'posNumber',
  ENERGY_TYPE: 'energyType',
  BIOFUEL_COMPONENT_CN: 'biofuelComponentCn',
  SUSTAINABLE_BIOFUEL: 'sustainableBiofuel',
  FUEL_TYPE: 'fuelType',
  FEEDSTOCK: 'feedstock',
  PRODUCTION_PATHWAY: 'productionPathway',
  BIOFUEL_TYPE: 'biofuelType',
  AMOUNT: 'amount',
  LOWER_CALORIFIC_VALUE_KG: 'lowerCalorificValueKg',
  HIGHER_CALORIFIC_VALUE_KG: 'higherCalorificValueKg',
  GHG_CAPACITY: 'ghgCapacity',
  LAND_USE_CATEGORY: 'landUseCategory',
  LAND_USE_EMISSIONS: 'landUseEmissions',
  PRODUCTION_UNIT_START_USAGE_DATE: 'productionUnitStartUsageDate',
  HYDROGEN_TECHNOLOGY_TYPE: 'technologyType',
  RFNBO: 'rfnbo'
};
