import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { accountTableFields } from '../../constants/accountTable';
import {
  Table,
  InputNumber,
  Button,
  message,
  Popconfirm,
  notification,
} from 'antd';
import _ from 'lodash';
import { translate } from 'react-i18next';
import { NAMESPACES, tCommon } from '../../i18n';
import { TableRowActions, TableActions } from '../';
import { goBack } from '../../utils/gotoLink';
import {
  translateBiofuelTypeClassificator,
  translateCleaningTechnologyClassificator,
  translateFeedstockClassificator,
  translateFuelTypeClassificator,
  translateHydrogenFuelTypeClassificator,
  translateHydrogenTechnologyTypeClassificator,
  translateProductionPathwayClassificator,
} from '../../utils/translateHelpers';
import gasType from '../../constants/classificators/gasType';

class PerformCertificatesTable extends Component {
  static propTypes = {
    dataList: PropTypes.array,
    t: PropTypes.func.isRequired,
    performAction: PropTypes.func.isRequired,
    validateAction: PropTypes.func,
    goAmountTotal: PropTypes.number,
    isCanceling: PropTypes.bool,
    isCancelAvailable: PropTypes.bool,
    isUsersOtherAccount: PropTypes.bool,
  };

  static defaultProps = {
    isCanceling: false,
    isCancelAvailable: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      dataList: props.dataList,
      certificatesAmount: this.recalculateAmount(props.dataList),
    };
  }

  onAmountChange = (value, rowId) => {
    this.setState(oldState => {
      const oldList = oldState.dataList.slice();

      const index = _.findIndex(
        oldState.dataList,
        item => item[accountTableFields.GO_SEARCH_RESPONSE_ROW_ID] === rowId
      );

      oldList[index] = {
        ...oldList[index],
        [accountTableFields.QUANTITY]: parseInt(value, 10) || 1,
      };

      return {
        ...oldState,
        dataList: oldList,
        certificatesAmount: this.recalculateAmount(oldList),
      };
    });
  };

  onRemove = rowId => {
    const { t } = this.props;
    this.setState(oldState => {
      if (oldState.dataList.length === 1) {
        message.warning(t('warningOne'));
        return oldState;
      }

      const dataList = _.remove(
        oldState.dataList,
        item => item[accountTableFields.GO_SEARCH_RESPONSE_ROW_ID] !== rowId
      );

      return {
        dataList,
        certificatesAmount: this.recalculateAmount(dataList),
      };
    });
  };

  getColumns = () => {
    const { t, isUsersOtherAccount, isCanceling, dataList } = this.props;

    let columns = [
      {
        title: t('table.eicwCode'),
        dataIndex: accountTableFields.EICW_CODE,
      },
      {
        title: t('table.productionTime'),
        dataIndex: accountTableFields.PRODUCTION_TIME,
      },
      {
        title: t('table.expirationTime'),
        dataIndex: accountTableFields.EXPIRATION_TIME,
      },
    ];

    if (dataList[0].gasType === gasType.BIOMETHANE_GAS_TYPE) {
      columns.push(
        {
          title: t('table.fuelType'),
          dataIndex: accountTableFields.FUEL_TYPE,
          render: translateFuelTypeClassificator,
        },
        {
          title: t('table.feedstock'),
          dataIndex: accountTableFields.FEEDSTOCK,
          render: translateFeedstockClassificator,
        },
        {
          title: t('table.biofuelType'),
          dataIndex: accountTableFields.BIOFUEL_TYPE,
          render: translateBiofuelTypeClassificator,
        },
        {
          title: t('table.productionPathway'),
          dataIndex: accountTableFields.PRODUCTION_PATHWAY,
          render: translateProductionPathwayClassificator,
        },
        {
          title: t('table.technology'),
          dataIndex: accountTableFields.TECHNOLOGY,
          render: translateCleaningTechnologyClassificator,
        }
      );
    }

    if (dataList[0].gasType === gasType.HYDROGEN_GAS_TYPE) {
      columns.push(
        {
          title: t('table.hydrogenFuelType'),
          dataIndex: accountTableFields.HYDROGEN_FUEL_TYPE,
          render: translateHydrogenFuelTypeClassificator,
        },
        {
          title: t('table.hydrogenTechnologyType'),
          dataIndex: accountTableFields.HYDROGEN_TECHNOLOGY,
          render: translateHydrogenTechnologyTypeClassificator,
        }
      );
    }

    columns.push({
      title: t('table.quantity'),
      dataIndex: accountTableFields.QUANTITY,
      render: (value, record) => {
        const rowId = record[accountTableFields.GO_SEARCH_RESPONSE_ROW_ID];

        return (
          <InputNumber
            min={1}
            max={record[accountTableFields.MAX_QUANTITY]}
            value={value}
            onChange={numberValue => this.onAmountChange(numberValue, rowId)}
          />
        );
      },
    });
    columns.push({
      title: t('table.gosPrice'),
      dataIndex: accountTableFields.GOS_PRICE,
      render: (value, record) => {
        if (isUsersOtherAccount) {
          record[accountTableFields.GOS_PRICE] = null;
          value = null;
        }
        const onChange = newValue => {
          this.setState(oldState => {
            record[accountTableFields.GOS_PRICE] = newValue;
            return oldState;
          });
        };
        const onBlur = event => {
          const newValue = event.target.value;
          let numberValue = null;
          if (newValue) {
            numberValue = Math.floor(+newValue * 100) / 100;
          }
          this.setState(oldState => {
            record[accountTableFields.GOS_PRICE] = numberValue;
            return oldState;
          });
        };

        return (
          <InputNumber
            min={0}
            defaultValue={null}
            step={0.01}
            value={value}
            onUpd
            onChange={onChange}
            onBlur={onBlur}
            disabled={isUsersOtherAccount}
          />
        );
      },
    });
    columns.push({
      title: t('table.actions'),
      dataIndex: accountTableFields.GO_SEARCH_RESPONSE_ROW_ID,
      render: value => (
        <TableRowActions>
          <Button
            shape="circle"
            icon="delete"
            size="small"
            type="primary"
            onClick={() => this.onRemove(value)}
          />
        </TableRowActions>
      ),
    });

    if (isCanceling) {
      columns = columns.filter(
        col => col.dataIndex !== accountTableFields.GOS_PRICE
      );
    }
    return columns;
  };

  recalculate = newState => newState;

  recalculateAmount = newState =>
    newState.reduce(
      (sum, current) => _.get(current, accountTableFields.QUANTITY) + sum,
      0
    );

  handlePerformClick = async () => {
    const {
      t,
      performAction,
      isCanceling,
      goAmountTotal,
      validateAction,
      isUsersOtherAccount,
    } = this.props;
    const { certificatesAmount, dataList } = this.state;

    if (!_.isNil(goAmountTotal) && goAmountTotal < certificatesAmount) {
      message.error(t('errorAmount'));
      return;
    }
    if (validateAction) {
      if (validateAction(dataList, isUsersOtherAccount)) {
        return;
      }
    }
    let result;
    let hasError = false;
    try {
      result = await performAction(this.recalculate(dataList));
    } catch (e) {
      hasError = true;
    }

    if (!(result instanceof Error) && !hasError && (!result || !result.error)) {
      message.success(
        t(isCanceling ? 'successPerformCancel' : 'successPerform')
      );
      goBack();
    } else {
      notification.warn({
        duration: 0,
        message: t(isCanceling ? 'failurePerformCancel' : 'failurePerform'),
      });
    }
  };

  render() {
    const { dataList, certificatesAmount } = this.state;
    const { t, isCanceling, isCancelAvailable } = this.props;

    return (
      <React.Fragment>
        <Table
          rowKey={accountTableFields.GO_SEARCH_RESPONSE_ROW_ID}
          dataSource={dataList}
          columns={this.getColumns()}
          pagination={false}
          scroll={{ x: true }}
          footer={() => (
            <div className="global-align-right">
              {t('footer', { certificates: certificatesAmount })}
            </div>
          )}
        />
        <TableActions>
          <Button onClick={goBack}>{t('buttonBack')}</Button>
          <Popconfirm
            title={
              isCanceling ? t('popconfirmTitleCancel') : t('popconfirmTitle')
            }
            onConfirm={this.handlePerformClick}
            okText={tCommon('popconfirm.buttonYes')}
            cancelText={tCommon('popconfirm.buttonNo')}
          >
            {(!isCanceling || isCancelAvailable) && (
              <Button type="primary" className="global-margin-left-10">
                {t('buttonPerform')}
              </Button>
            )}
          </Popconfirm>
        </TableActions>
      </React.Fragment>
    );
  }
}

export default translate(NAMESPACES.transferringCertificates)(
  PerformCertificatesTable
);
