import React from 'react';
import { Layout, Tabs } from 'antd';
import { translate } from 'react-i18next';
import { NAMESPACES } from '../../i18n';
import PropTypes from 'prop-types';
import { AccountOverviewFilter, AccountReportFilter } from '../../forms';
import { AccountSummary, AccountReport } from '../../containers';
import { getEntityById } from '../../utils/asyncHelpers';
import { connect } from 'react-redux';
import accountSchema from '../../schemas/account';
import PropTypesImmutable from 'react-immutable-proptypes';
import { translateLegalEntityTypeClassificator } from '../../utils/translateHelpers';
import { accountFields } from '../../constants/account';
import { currentPathSelector } from '../../utils/gotoLink';
import './AccountOverview.css';
import * as userSelectors from '../../reducers/userReducer';
import { accountType } from '../../constants/classificators';

const tabKeys = {
  ACCOUNT_OVERVIEW: 'accountOverview',
  ACCOUNT_REPORT: 'accountReport',
};

class AccountOverviewPage extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    accountEntity: PropTypesImmutable.map,
    accountId: PropTypes.string,
    currentPath: PropTypes.string.isRequired,
    userAccounts: PropTypes.array,
  };

  constructor() {
    super();
    this.state = {
      checkedFields: [],
      shownFields: [],
    };
  }
  changeField = (newField, checked) => {
    if (checked) {
      this.setState(state => ({
        checkedFields: [...state.checkedFields, newField],
      }));
    } else {
      this.setState(state => ({
        checkedFields: [...state.checkedFields.filter(str => str !== newField)],
      }));
    }
  };
  resetFields = () => {
    console.log('clear');
    this.setState(state => ({
      checkedFields: [],
      shownFields: [],
    }));
  };
  renderFields = () => {
    this.setState(state => ({
      shownFields: [...state.checkedFields],
    }));
  };
  fieldsIncludes = field => {
    return this.state.shownFields.includes(field);
  };

  render() {
    const {
      t,
      accountEntity,
      accountId,
      currentPath,
      biomethaneAccountEntity,
      hydrogenAccountEntity,
    } = this.props;

    let roleCaption = '';
    if (accountEntity) {
      roleCaption = translateLegalEntityTypeClassificator(
        accountEntity.get(accountFields.TYPE)
      );
    }
    if (biomethaneAccountEntity || hydrogenAccountEntity) {
      roleCaption = translateLegalEntityTypeClassificator(
        'MANUFACTURER_GENERAL'
      );
    }
    let manufacture = false;
    let accountIds = [];
    if (accountId) {
      accountIds.push(accountId);
    }
    if (biomethaneAccountEntity) {
      accountIds.push(biomethaneAccountEntity.get(accountFields.ID));
      manufacture = true;
    }
    if (hydrogenAccountEntity) {
      accountIds.push(hydrogenAccountEntity.get(accountFields.ID));
      manufacture = true;
    }

    if (!accountId && !biomethaneAccountEntity && !hydrogenAccountEntity) {
      return (
        <Layout className="page-AccountOverviewPage main-container">
          <h1>{t('titleAdmin')}</h1>
          <AccountReportFilter t={t} />
          <div className="page-AccountOverviewPage__table-container">
            <AccountReport t={t} />
          </div>
        </Layout>
      );
    }

    return (
      <Layout className="page-AccountOverviewPage main-container">
        <h1>{t('title', { roleCaption: roleCaption.toLowerCase() })}</h1>
        <Tabs
          type="card"
          onChange={this.handleTabChange}
          defaultActiveKey={
            currentPath.includes('/accountReport')
              ? tabKeys.ACCOUNT_REPORT
              : tabKeys.ACCOUNT_OVERVIEW
          }
        >
          <Tabs.TabPane
            key={tabKeys.ACCOUNT_OVERVIEW}
            tab={t('accountOverviewTab')}
            className="page-AccountOverviewPage__filter-container"
          >
            <AccountOverviewFilter
              accountIds={accountIds}
              manufacture={manufacture}
              t={t}
              changeField={this.changeField}
              renderFields={this.renderFields}
              resetFields={this.resetFields}
            />
            <div className="page-AccountOverviewPage__table-container">
              <AccountSummary
                accountIds={accountIds}
                manufacture={manufacture}
                t={t}
                fieldsIncludes={this.fieldsIncludes}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            key={tabKeys.ACCOUNT_REPORT}
            tab={t('accountReportTab')}
          >
            <AccountReportFilter accountIds={accountIds} t={t} />
            <div className="page-AccountOverviewPage__table-container">
              <AccountReport accountIds={accountIds} t={t} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userAccounts = userSelectors.getUserLegalEntityAccounts(state).toJS();
  let biomethaneProducer = false;
  let hydrogenProducer = false;
  let biomethaneProducerId = null;
  let hydrogenProducerId = null;
  let accountId = ownProps.match.params.accountId;
  if (accountId === 'manufacture') {
    accountId = null;
    userAccounts.forEach(account => {
      if (account[accountFields.TYPE] === accountType.MANUFACTURER) {
        biomethaneProducer = true;
        biomethaneProducerId = account[accountFields.ID];
      }
      if (account[accountFields.TYPE] === accountType.HYDROGEN_MANUFACTURER) {
        hydrogenProducer = true;
        hydrogenProducerId = account[accountFields.ID];
      }
    });
  }
  return {
    currentPath: currentPathSelector(state),
    accountId: accountId,
    accountEntity:
      accountId &&
      getEntityById(state, accountSchema, ownProps.match.params.accountId),
    biomethaneAccountEntity:
      biomethaneProducer &&
      getEntityById(state, accountSchema, biomethaneProducerId),
    hydrogenAccountEntity:
      hydrogenProducer &&
      getEntityById(state, accountSchema, hydrogenProducerId),
  };
};

export default translate(NAMESPACES.account)(
  connect(mapStateToProps)(AccountOverviewPage)
);
